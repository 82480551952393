require("@fontsource/ubuntu/400.css")
require("@fontsource/ubuntu/700.css")

require("@fortawesome/fontawesome-free/css/fontawesome.css")
require("@fortawesome/fontawesome-free/css/solid.css")
require("@fortawesome/fontawesome-free/css/brands.css")

// need this to force re-render of google custom search input on route change...
// exports.onRouteUpdate = ({ location, prevLocation }) => {
//   if (!document.querySelector('.gsc-control-cse') && window.google?.search?.cse) window.google.search.cse.element.go()
// }
